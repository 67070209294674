jQuery.noConflict();
(function($) {
$( document ).ready(function() {
// andherewego.exe




/*******************************
// Header Image Parallax
*******************************/
$(window).on('scroll', function() {
    yPos = window.pageYOffset;
    shift = yPos * 0.4 + 'px';
    move = yPos * 0.7 + 'px';
    $('#headerimage').css('top', shift);
    $('#top-bar-menu').css('top', move);
});



/*******************************
// Services
*******************************/
$( ".loadtestimonials" ).click(function(e) {
    (e).preventDefault();
    var testcat = $(this).attr('href');
    if (!$(this).hasClass('active')) {
        $('.loadtestimonials').removeClass('active');
        $(this).addClass('active');
    }
    if ($('body').hasClass('mobile')) {
        $(testcat).foundation('reveal', 'open', {
          animation: 'fade',
          animation_speed: 300,
        });
    }
    else {
        if ($(testcat).is(":hidden"))  {    // you get the idea...
            $('.testimonial-content').slideUp("fast");
        }
        $(testcat).slideDown( "slow", function() {});
    }
});


/*******************************
// Marquee/Accordion Navigation
*******************************/
$(".menutoggle").on("click", function(a) {
    //a.preventDefault();
    var e = $(this),
        i = $("body");
    if (i.hasClass("is-navbar")) e.removeClass("is-active"), i.removeClass("is-navbar"), setTimeout(function() {
        $("#js-navbar a.c-navbar__link div.c-navbar__link-text").marquee("destroy");
    }, 600);
    else {
        $("#js-navbar a.c-navbar__link div.c-navbar__link-text").marquee("destroy");
        $("#js-navbar a.c-navbar__link:not(.is-current) div.c-navbar__link-text").marquee({
            duplicated: !0,
            duration: 50e3,
            gap: 0,
            pauseOnHover: !0,
            startVisible: !0
        });
        $("#js-navbar a.c-navbar__link.is-current div.c-navbar__link-text").marquee({
            duplicated: !0,
            duration: 50e3,
            gap: 0,
            startVisible: !0
        }).marquee("pause"), e.addClass("is-active"), i.addClass("is-navbar");
    }
});

/*
function closeNav(){
    var listitems = $('ul#menu-primary-nav-1>li.menu-item');    
    if(listitems.hasClass('collapse')) {
        setTimeout(function() {
            listitems.removeClass('collapse').css('z-index', 'initial');
            $('#menu #goback').removeClass('on');
        }, 750);
    }
    listitems.find('ul').removeClass('open');
}
$('.menutoggle').click(function(event){
    $(this).toggleClass('open');
    //$('#navScreen').fadeToggle('fast');
    if($('#navScreen').hasClass('open')) {
        $('#navScreen').removeClass('open');
        setTimeout(closeNav, 1000);
    } else {
        $('#navScreen').addClass('open');
    }
});
$('ul#menu-primary-nav-1 > li.menu-item-has-children > a').click(function(event){
    var mainitem = $(this).parent();
    var listitems = $('ul#menu-primary-nav-1>li.menu-item').not(mainitem);
    event.preventDefault();
    $(mainitem).addClass('expanded');
    listitems.addClass('collapse').delay(100).css('z-index', '-99');
    if($(mainitem).find('ul').hasClass('open')) {
        closeNav();
    } else {
        setTimeout(function() {
            $(mainitem).find('ul').addClass('open');
        }, 750);
    }
    $('#menu #goback').delay(1000).addClass('on');
});
$('#menu #goback').click(function(event){ closeNav(); });
*/

/*******************************
// mobile subnavigation
*******************************/
$('.opensub').click(function(event){
    $(this).toggleClass('open');
    $('#sidenav').slideToggle('fast');
    if($('#sidenav').hasClass('open')) {
        $('#sidenav').removeClass('open');
    } else {
        $('#sidenav').addClass('open');
    }
});


/*******************************
// join our team
*******************************/
$('.accordion').click(function(event){
    $(this).next('div').slideToggle('fast');
});




/*******************************
// foxycart gift cards equalizer fix
*******************************/
$('#gift_delivery').click(function() {
    setTimeout(function(){
        $('[data-equalizer]').foundation('_reflow');
    }, 500);
});




/*******************************
// Educator / Team Modals
*******************************/

// modal horizontal scroll
$('.edu_modal .edu_scroll').mousewheel(function(e, delta) {
    this.scrollLeft -= (delta);
    e.preventDefault();
});

// fade in modal on click
$('body').on('click', '.educator a', function (){
    var edu_id = $(this).attr('id');
    $('.edu_modal[data-edu='+edu_id+']').fadeIn();
});
$('body').on('click', '.team_member a', function (){
    var team_id = $(this).attr('id');
    $('.team_modal[data-edu='+team_id+']').fadeIn();
    $('#theteam').addClass('modal-on');
    $('.team_modal:not([data-edu='+team_id+'])').fadeOut();
    $('html, body').animate({
        scrollTop: $('#theteam').offset().top - 20
    }, 'slow');
});

// close modal
$(".edu_close").click(function(){
    $(this).parent().fadeOut();
    $('#theteam').removeClass('modal-on');
});

// team dropdown filter actions
/*$(".team_step").mouseleave(function(){
    window.setTimeout(function(){
        $(this).find('.dropdown-pane').addClass('okay');
    }, 500);
});*/
$(".team_label").click(function(){
    if ( !$(this).hasClass("open") ) {
        $(this).next(".filter-pane").slideDown('fast');
        $(this).addClass('open');

    } else {
        $(this).next(".filter-pane").slideUp('fast');        
        $(this).removeClass('open');
    }
    $(".team_label").not(this).next(".filter-pane").slideUp('fast');
    $(".team_label").not(this).removeClass('open');
});


/*******************************
// Featherlight Gallery -- Add captions via alt tag
*******************************/
$.featherlightGallery.prototype.afterContent = function() {
    var caption = this.$currentTarget.find('img').attr('alt');
    this.$instance.find('.caption').remove();
    $('<div class="caption">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
};






/*******************************
// Team Page
*******************************/


$('#teamfilter').on( 'click', 'input, radio', function( event ) {
    
   // $('.team_label.open').removeClass('open');

var form = $('#teamfilter'); //  form ID

$.ajax({
    url: '/wp-content/themes/Imaginal-Master/parts/loop-team_query.php',
    type: 'POST',
    data: form.serialize(),

    
    beforeSend: function() {
        $('#theteam').find( '.team_member' ).remove();
        $('.archive-title').find('span').text('').addClass('started');

        $('#theteam').append( '<div class="page-content" id="loader">Loading New Posts...</div>' );
    },
    success: function( html ) {
        
        if ($('#drop-city input').is(':checked')) { var city = $("#drop-city input:checked").val(); $('.archive-title').find('span').append(city); if ($('#drop-service input, #drop-experience input').is(':checked'))  { $('.archive-title').find('span').append(", ");} }
        if ($('#drop-service input').is(':checked')) { var service = $("#drop-service input:checked").val(); $('.archive-title').find('span').append(service +" Services");  if ($('#drop-experience input').is(':checked'))  { $('.archive-title').find('span').append(", ");} }
        if ($('#drop-experience input').is(':checked')) { var experience = $("#drop-experience input:checked").val(); $('.archive-title').find('span').append(experience +"+ years experience"); }
    

        $('#theteam #loader').remove();
        $('#theteam').append( html );
        
        // init Isotope
        var $grid =  $('#theteam');
        $checkboxes = $('#filters input');
        var $newItems = $grid.children();
        $grid.hide().prepend( $newItems);
        $grid.isotope( 'reloadItems' );
        setTimeout(function() {
            $grid.fadeIn(1000).isotope({ sortBy: 'original-order', layoutMode: 'masonry' });    
        }, 2000);
        // close modal if open
        $(".team_modal").fadeOut();
  
    },
    
    
    
});


});


$(function(){
  
   // init Isotope
$('#theteam').isotope({
    itemSelector: '.team_member'
  });

var $grid =  $('#theteam').isotope({
    itemSelector: '.team_member',
    layoutMode: 'masonry'
  }),
    $checkboxes = $('#filters input');


$checkboxes.change(function(){
    

    var filters = [];
    // get checked checkboxes values
    $checkboxes.filter(':checked').each(function(){
      filters.push( this.value );
    });
    // ['.red', '.blue'] -> '.red, .blue'
    filters = filters.join(', ');
    $grid.isotope({ filter: filters });
    var $newItems = $grid.children();
        $grid.prepend( $newItems).isotope( 'reloadItems' ).isotope({ sortBy: 'original-order' });
  


 
        
          });
  


    
});




     var hash = window.location.hash;

    if (hash == ""){
      
    }
    
    if (hash){
    // Checking radio buttons for locations based on hash passed from locations page links
        $(hash).prop('checked', true);
        $(hash).click();
        }
    
        
// Enables clearing of all filters on Expertise + Service via Expertise "Clear Selection" button
function uncheckAll() {
  $("#drop-expertise input[type='checkbox']:checked").prop("checked", false);
  $("#drop-service input[type='radio']:checked").prop("checked", false);
  console.log('herp derp');
      var $grid =  $('#theteam').isotope({
        itemSelector: '.team_member',
        layoutMode: 'masonry'
      }),
    $checkboxes = $('#filters input');
    var filters = [];
    $checkboxes.filter(':checked').each(function(){
      filters.push( this.value );
    });
    // ['.red', '.blue'] -> '.red, .blue'
    filters = filters.join(', ');
    $grid.isotope({ filter: filters });
    var $newItems = $grid.children();
        $grid.prepend( $newItems).isotope( 'reloadItems' ).isotope({ sortBy: 'original-order' });
    // reload isotope somehow here --- the above code isn't working...
}
$('a.clearselection').on('click', uncheckAll);


// Likewise, clear other filters when another location's chosen
$('#drop-city input').on('click', uncheckAll);







// end it all
});
})(jQuery);